
export default {
  props: {
    carousel: {
      type: Object,
      default: () => [],
      required: false,
    },
  },
  data() {
    return {
      active: 0
    };
  },
  methods: {
    routeTo(path) {
      if (path) {
        this.$router.push(path);
      }
    },
  },
};
